const LogoDark = ({ light }) => (
    <svg width="100%" height="100%" viewBox="0 0 523 81"
        version="1.1"
        fillRule="evenodd" clipRule="evenodd" strokeLinejoin="round" strokeMiterlimit={2}
    >
        <g>
            <path
                d="M35.913,80.334c-10.191,0 -18.724,-3.909 -25.6,-11.727c-6.875,-7.818 -10.313,-16.962 -10.313,-27.432c0,-10.75 3.211,-19.632 9.633,-26.647c6.422,-7.016 14.833,-10.523 25.233,-10.523c8.237,0 15.357,1.972 21.36,5.916c6.003,3.943 9.004,8.638 9.004,14.082c0,2.443 -0.785,4.433 -2.356,5.968c-1.57,1.536 -3.682,2.304 -6.334,2.304c-2.373,0 -4.502,-0.716 -6.387,-2.147c-1.885,-1.431 -3.438,-3.158 -4.659,-5.182c-1.222,-2.025 -2.461,-4.066 -3.717,-6.126c-1.257,-2.059 -2.775,-3.804 -4.555,-5.235c-1.78,-1.431 -3.822,-2.146 -6.125,-2.146c-3.49,0 -6.474,1.64 -8.952,4.921c-2.478,3.281 -3.717,7.713 -3.717,13.297c0,9.842 2.373,17.922 7.12,24.239c4.746,6.317 10.226,9.476 16.438,9.476c2.304,0 4.415,-0.349 6.335,-1.047c1.919,-0.698 3.629,-1.693 5.13,-2.984c1.501,-1.292 2.653,-2.426 3.455,-3.403c0.803,-0.977 1.728,-2.199 2.775,-3.665c0.977,-1.186 2.199,-1.588 3.665,-1.204c1.465,0.384 2.163,1.414 2.094,3.089c-0.28,7.259 -2.949,13.437 -8.01,18.532c-5.061,5.096 -12.233,7.644 -21.517,7.644Z"
                fillRule="nonzero" fill={light ? "white" : "black"} />

            <path
                d="M120.2,67.874c0.209,1.675 0.61,3.019 1.204,4.031c0.593,1.012 1.186,1.658 1.78,1.937c0.593,0.279 1.134,0.628 1.623,1.047c0.488,0.419 0.732,0.942 0.732,1.571c0,2.303 -3.943,3.455 -11.831,3.455c-3.211,0 -5.602,-0.384 -7.172,-1.152c-1.571,-0.768 -2.565,-2.059 -2.984,-3.874c-2.653,3.63 -7.085,5.445 -13.298,5.445c-5.863,0 -10.627,-1.361 -14.292,-4.084c-3.664,-2.722 -5.497,-6.666 -5.497,-11.831c0,-5.026 1.815,-8.952 5.445,-11.779c3.63,-2.827 8.097,-4.241 13.402,-4.241c4.118,0 7.643,0.873 10.575,2.618l0,-0.314c-0.698,-6.911 -1.483,-11.588 -2.356,-14.031c-0.872,-2.443 -2.565,-3.664 -5.078,-3.664c-1.605,0 -2.897,0.733 -3.874,2.199c-0.977,1.465 -1.693,3.071 -2.146,4.816c-0.454,1.745 -1.309,3.35 -2.566,4.816c-1.256,1.466 -2.931,2.199 -5.025,2.199c-2.164,0 -3.927,-0.628 -5.288,-1.885c-1.361,-1.256 -2.042,-2.896 -2.042,-4.921c0,-4.048 2.025,-7.102 6.073,-9.161c4.049,-2.059 9.005,-3.089 14.868,-3.089c6.98,0 12.757,1.902 17.329,5.706c4.572,3.805 7.276,8.952 8.114,15.444l2.304,18.742Zm-24.187,7.12c1.606,0 2.914,-0.768 3.926,-2.304c1.013,-1.535 1.519,-3.28 1.519,-5.235l-0.419,-4.816c-0.698,-5.794 -2.862,-8.691 -6.492,-8.691c-1.605,0 -2.897,0.646 -3.874,1.937c-0.977,1.292 -1.466,3.019 -1.466,5.183c0,3.909 0.611,7.207 1.833,9.895c1.221,2.687 2.879,4.031 4.973,4.031Z"
                fillRule="nonzero" fill={light ? "white" : "black"} />
            <path
                d="M163.233,27.982c3.001,0 5.392,0.977 7.172,2.932c1.78,1.954 2.67,4.432 2.67,7.434c0,6.561 -2.688,9.842 -8.062,9.842c-2.164,0 -3.805,-0.506 -4.921,-1.518c-1.117,-1.013 -1.763,-2.147 -1.937,-3.403c-0.175,-1.257 -0.541,-2.391 -1.1,-3.403c-0.558,-1.012 -1.396,-1.518 -2.513,-1.518c-2.582,0 -3.874,3.35 -3.874,10.051l0,19.475c0,1.745 0.925,3.473 2.775,5.183c1.85,1.71 2.775,2.984 2.775,3.822l0,0.418c0,1.327 -0.838,1.99 -2.513,1.99l-22.512,0c-1.675,0 -2.512,-0.663 -2.512,-1.99l0,-0.418c0,-0.838 0.698,-2.112 2.094,-3.822c1.396,-1.71 2.094,-3.438 2.094,-5.183l0,-20.836c0,-1.396 -0.419,-2.583 -1.257,-3.56c-0.837,-0.977 -1.675,-1.815 -2.513,-2.513c-0.837,-0.698 -1.256,-1.466 -1.256,-2.303l0,-0.314c0,-1.187 0.803,-2.199 2.408,-3.037l14.763,-6.91c1.396,-0.489 2.374,-0.524 2.932,-0.105c0.558,0.419 0.838,1.222 0.838,2.408l0,7.644c2.373,-6.911 7.189,-10.366 14.449,-10.366Z"
                fillRule="nonzero" fill={light ? "white" : "black"} />
            <path
                d="M228.149,67.874c0,1.745 0.698,3.473 2.094,5.183c1.396,1.71 2.094,2.984 2.094,3.822l0,0.418c0,1.327 -0.803,1.99 -2.408,1.99l-14.973,0c-1.815,0 -2.722,-0.873 -2.722,-2.618l0,-0.942c-3.281,3.071 -7.539,4.607 -12.774,4.607c-6.771,0 -12.582,-2.321 -17.433,-6.963c-4.851,-4.642 -7.277,-11.046 -7.277,-19.213c0,-7.609 2.443,-13.873 7.329,-18.794c4.887,-4.921 10.715,-7.382 17.486,-7.382c4.258,0 7.853,0.977 10.784,2.932l0,-13.926c0,-1.396 -0.453,-2.6 -1.361,-3.612c-0.907,-1.012 -1.832,-1.885 -2.775,-2.618c-0.942,-0.733 -1.413,-1.518 -1.413,-2.356l0,-0.314c0,-1.326 0.803,-2.233 2.408,-2.722l16.334,-4.921c3.071,-0.977 4.607,-0.14 4.607,2.513l0,64.916Zm-24.501,7.329c1.885,0.07 3.473,-0.698 4.764,-2.303c1.292,-1.606 1.937,-3.77 1.937,-6.492l0,-20.208c0,-3.35 -0.959,-6.369 -2.879,-9.056c-1.92,-2.688 -4.101,-4.032 -6.544,-4.032c-5.165,0 -7.923,4.293 -8.272,12.879c-0.139,3.281 0.157,7.085 0.89,11.413c0.733,4.327 1.99,8.359 3.77,12.093c1.78,3.734 3.891,5.636 6.334,5.706Z"
                fillRule="nonzero" fill={light ? "white" : "black"} />
            <path
                d="M269.926,27.982c6.491,0 12.232,2.216 17.223,6.649c4.991,4.432 7.487,10.627 7.487,18.585c0,8.027 -2.426,14.553 -7.277,19.579c-4.852,5.026 -10.663,7.539 -17.433,7.539c-5.166,0 -9.389,-1.536 -12.67,-4.607l0,0.942c0,1.815 -0.907,2.722 -2.722,2.722l-15.81,-0.104c-1.606,0 -2.408,-0.663 -2.408,-1.99l0,-0.418c0,-0.838 0.698,-2.112 2.094,-3.822c1.396,-1.71 2.094,-3.473 2.094,-5.288l0,-47.64c0,-1.815 -0.855,-3.507 -2.565,-5.078c-1.711,-1.57 -2.566,-2.775 -2.566,-3.612l0,-0.419c0,-1.117 0.943,-2.094 2.827,-2.932l16.125,-7.434c1.326,-0.698 2.478,-0.837 3.455,-0.418c0.977,0.418 1.466,1.361 1.466,2.827l0,28.06c2.722,-2.094 6.282,-3.141 10.68,-3.141Zm-1.676,47.221c5.305,0 7.958,-4.397 7.958,-13.192c0,-4.189 -0.332,-8.377 -0.995,-12.565c-0.663,-4.188 -1.85,-7.957 -3.56,-11.308c-1.71,-3.35 -3.752,-5.026 -6.125,-5.026c-1.885,0 -3.403,0.821 -4.555,2.461c-1.151,1.64 -1.727,3.891 -1.727,6.753l0,20.313c0,3.211 0.925,6.108 2.774,8.69c1.85,2.583 3.927,3.874 6.23,3.874Z"
                fillRule="nonzero" fill={light ? "white" : "black"} />
            <path
                d="M328.036,80.334c-8.097,0 -14.955,-2.496 -20.574,-7.487c-5.619,-4.99 -8.429,-11.255 -8.429,-18.794c0,-7.538 2.792,-13.768 8.376,-18.689c5.585,-4.921 12.216,-7.382 19.894,-7.382c7.958,0 14.728,2.478 20.313,7.434c5.584,4.956 8.376,11.238 8.376,18.847c0,7.538 -2.722,13.768 -8.167,18.689c-5.445,4.921 -12.041,7.382 -19.789,7.382Zm2.199,-5.131c4.467,0 6.701,-4.293 6.701,-12.878c0,-3.141 -0.436,-6.876 -1.309,-11.204c-0.873,-4.327 -2.338,-8.411 -4.398,-12.25c-2.059,-3.839 -4.345,-5.759 -6.858,-5.759c-2.443,0 -4.188,1.082 -5.235,3.246c-1.047,2.164 -1.57,5.445 -1.57,9.842c0,2.304 0.279,5.026 0.837,8.167c0.559,3.141 1.327,6.3 2.304,9.476c0.977,3.176 2.321,5.863 4.031,8.062c1.71,2.199 3.542,3.298 5.497,3.298Z"
                fillRule="nonzero" fill={light ? "white" : "black"} />
            <path
                d="M410.647,67.874c0.209,1.675 0.611,3.019 1.204,4.031c0.593,1.012 1.187,1.658 1.78,1.937c0.593,0.279 1.134,0.628 1.623,1.047c0.489,0.419 0.733,0.942 0.733,1.571c0,2.303 -3.944,3.455 -11.832,3.455c-3.21,0 -5.601,-0.384 -7.172,-1.152c-1.57,-0.768 -2.565,-2.059 -2.984,-3.874c-2.652,3.63 -7.085,5.445 -13.297,5.445c-5.864,0 -10.628,-1.361 -14.292,-4.084c-3.665,-2.722 -5.497,-6.666 -5.497,-11.831c0,-5.026 1.815,-8.952 5.444,-11.779c3.63,-2.827 8.098,-4.241 13.403,-4.241c4.118,0 7.643,0.873 10.575,2.618l0,-0.314c-0.698,-6.911 -1.484,-11.588 -2.356,-14.031c-0.873,-2.443 -2.566,-3.664 -5.078,-3.664c-1.606,0 -2.897,0.733 -3.874,2.199c-0.978,1.465 -1.693,3.071 -2.147,4.816c-0.454,1.745 -1.309,3.35 -2.565,4.816c-1.257,1.466 -2.932,2.199 -5.026,2.199c-2.164,0 -3.926,-0.628 -5.287,-1.885c-1.362,-1.256 -2.042,-2.896 -2.042,-4.921c0,-4.048 2.024,-7.102 6.073,-9.161c4.048,-2.059 9.004,-3.089 14.868,-3.089c6.98,0 12.756,1.902 17.328,5.706c4.572,3.805 7.277,8.952 8.115,15.444l2.303,18.742Zm-24.186,7.12c1.605,0 2.914,-0.768 3.926,-2.304c1.012,-1.535 1.518,-3.28 1.518,-5.235l-0.419,-4.816c-0.698,-5.794 -2.862,-8.691 -6.491,-8.691c-1.606,0 -2.897,0.646 -3.874,1.937c-0.978,1.292 -1.466,3.019 -1.466,5.183c0,3.909 0.611,7.207 1.832,9.895c1.222,2.687 2.879,4.031 4.974,4.031Z"
                fillRule="nonzero" fill={light ? "white" : "black"} />
            <path
                d="M453.68,27.982c3.002,0 5.392,0.977 7.172,2.932c1.78,1.954 2.67,4.432 2.67,7.434c0,6.561 -2.687,9.842 -8.062,9.842c-2.164,0 -3.804,-0.506 -4.921,-1.518c-1.117,-1.013 -1.762,-2.147 -1.937,-3.403c-0.174,-1.257 -0.541,-2.391 -1.099,-3.403c-0.559,-1.012 -1.396,-1.518 -2.513,-1.518c-2.583,0 -3.874,3.35 -3.874,10.051l0,19.475c0,1.745 0.925,3.473 2.774,5.183c1.85,1.71 2.775,2.984 2.775,3.822l0,0.418c0,1.327 -0.838,1.99 -2.513,1.99l-22.511,0c-1.675,0 -2.513,-0.663 -2.513,-1.99l0,-0.418c0,-0.838 0.698,-2.112 2.094,-3.822c1.396,-1.71 2.094,-3.438 2.094,-5.183l0,-20.836c0,-1.396 -0.419,-2.583 -1.256,-3.56c-0.838,-0.977 -1.676,-1.815 -2.513,-2.513c-0.838,-0.698 -1.257,-1.466 -1.257,-2.303l0,-0.314c0,-1.187 0.803,-2.199 2.409,-3.037l14.763,-6.91c1.396,-0.489 2.373,-0.524 2.931,-0.105c0.559,0.419 0.838,1.222 0.838,2.408l0,7.644c2.373,-6.911 7.19,-10.366 14.449,-10.366Z"
                fillRule="nonzero" fill={light ? "white" : "black"} />
            <path
                d="M518.596,67.874c0,1.745 0.698,3.473 2.094,5.183c1.396,1.71 2.094,2.984 2.094,3.822l0,0.418c0,1.327 -0.802,1.99 -2.408,1.99l-14.972,0c-1.815,0 -2.723,-0.873 -2.723,-2.618l0,-0.942c-3.28,3.071 -7.538,4.607 -12.773,4.607c-6.771,0 -12.582,-2.321 -17.434,-6.963c-4.851,-4.642 -7.276,-11.046 -7.276,-19.213c0,-7.609 2.443,-13.873 7.329,-18.794c4.886,-4.921 10.714,-7.382 17.485,-7.382c4.258,0 7.853,0.977 10.785,2.932l0,-13.926c0,-1.396 -0.454,-2.6 -1.361,-3.612c-0.908,-1.012 -1.833,-1.885 -2.775,-2.618c-0.942,-0.733 -1.414,-1.518 -1.414,-2.356l0,-0.314c0,-1.326 0.803,-2.233 2.409,-2.722l16.333,-4.921c3.072,-0.977 4.607,-0.14 4.607,2.513l0,64.916Zm-24.5,7.329c1.884,0.07 3.472,-0.698 4.764,-2.303c1.291,-1.606 1.937,-3.77 1.937,-6.492l0,-20.208c0,-3.35 -0.96,-6.369 -2.88,-9.056c-1.919,-2.688 -4.101,-4.032 -6.544,-4.032c-5.165,0 -7.922,4.293 -8.271,12.879c-0.14,3.281 0.157,7.085 0.89,11.413c0.733,4.327 1.989,8.359 3.769,12.093c1.78,3.734 3.892,5.636 6.335,5.706Z"
                fillRule="nonzero" fill={light ? "white" : "black"} />
        </g>
    </svg>
)

export default LogoDark